import "./widget.scss";
import eyeIcon from "../../images/eyeIcon.png";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom"; // Importar Link desde React Router

const Widget = ({ type, data }) => {
  const { currentUser } = useContext(AuthContext);

  let widgetInfo;
  let widgetClass = "";

  switch (type) {
    case "vigilantes":
      widgetInfo = {
        title: "VIGILANTES",
        query: "vigilantes",
        icon: <Link to="/vigilantes"><img src={eyeIcon} alt="Eye Icon" className="icon" /></Link>, // Enlace a la ruta correspondiente
      };
      widgetClass = "widget-type1";
      break;
    case "tiendas":
      widgetInfo = {
        title: "SERVICIOS",
        query: "tiendas",
        icon: <Link to="/clientes"><img src={eyeIcon} alt="Eye Icon" className="icon" /></Link>, // Enlace a la ruta correspondiente
      };
      widgetClass = "widget-type2";
      break;
    case "documents":
      widgetInfo = {
        title: "DOCUMENTS",
        query: "documents",
        icon: <Link to="/documentos"><img src={eyeIcon} alt="Eye Icon" className="icon" /></Link>, // Enlace a la ruta correspondiente
      };
      widgetClass = "widget-type3";
      break;
    default:
      widgetInfo = {
        title: "",
        query: "",
        icon: null,
      };
      widgetClass = "";
      break;
  }

  return (
    <div className={`widget ${widgetClass}`}>
      <div className="left">
        <span className="title">{widgetInfo.title}</span>
        <span className="counter">{data.amount}</span>
        <span className="percentage">{data.growthPercentage ? data.growthPercentage : 0} Último mes</span>
      </div>
      <div className="right">
        {widgetInfo.icon}
      </div>
    </div>
  );
};

export default Widget;
