import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { db } from '../../firebase'
import { collection, getDocs, where, query } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import VigilanteBarChart from '../../components/chart/VigilanteBarChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente para mostrar información detallada de un vigilante de seguridad.
 *
 * @returns {JSX.Element} - Componente Single.
 */
const Single = () => {

  const [vigilante, setVigilante] = useState([]);
  const { tipVS } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "singleContainer collapsed" : "singleContainer";

  /**
    * Recupera los documentos de usuario relacionados con el vigilante de seguridad.
    */
  const fetchUserDocuments = async () => {
    try {
      const serviciosRef = collection(db, 'servicios');
      const q = query(
        serviciosRef,
        where('admins', 'array-contains', currentUser.email)
      );
      const querySnapshot = await getDocs(q);
      const documents = [];

      const promises = querySnapshot.docs.map(async (doc) => {
        const vigilantesRef = collection(serviciosRef, doc.id, 'vigilantes');
        const qVigilantes = query(vigilantesRef, where('tipVS', '==', tipVS));
        const vigilantesSnapshot = await getDocs(qVigilantes);

        vigilantesSnapshot.forEach((vigilanteDoc) => {
          const vigilanteData = { id: vigilanteDoc.id, ...vigilanteDoc.data() };
          documents.push(vigilanteData);
          console.log(vigilanteData, 'vigilanteData');
        });
      });

      await Promise.all(promises);

      setVigilante(documents);
    } catch (error) {
      console.error('Error al obtener los datos del vigilante:', error);
    }
  };

  useEffect(() => {
    fetchUserDocuments();
  }, [tipVS]);

  return (
    <div className="single">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="item">
              {/* Verifica si hay una foto, si no, muestra el icono por defecto */}
              {vigilante?.[0]?.foto ?
                <img src={vigilante[0].foto} alt="Foto del vigilante" className="itemImg" /> :
                <AccountCircleIcon fontSize="inherit" />
              }
              <div className="details">
                <h2 className="itemTitle">{vigilante?.[0]?.nombre} {vigilante?.[0]?.apellidos}</h2>
                <p>ID: {vigilante?.[0]?.id}</p>
                <p>tipVS: {vigilante?.[0]?.tipVS}</p>
              </div>
            </div>
            <hr />
            {vigilante?.[0]?.tiendas && (
              <div className="detailItem">
                <span className="itemKey">Servicios: </span>
                <div className="tiendasContainer">
                  {vigilante[0].tiendas.map((tienda, index) => (
                    <div key={index} className="tiendaItem">
                      <span className="itemValue" key={index}>{tienda.numero}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="contactInfo">
              <PhoneIcon />
              {vigilante?.[0]?.telefono}
              <EmailIcon />
              {vigilante?.[0]?.correo}
            </div>
          </div>
          <div className="right">
            <VigilanteBarChart vigilante={vigilante?.[0]?.tipVS} />
          </div>
        </div>
        <div className="bottom">
          <List vigilante={vigilante?.[0]?.tipVS} />
        </div>
      </div>
    </div>
  );
}

export default Single;